<template>
 <div class="login">
     <div class="fliter"></div>
     <div class="tt1 animated fadeInUp"></div>
     <div class="l-title">沙井头村城市更新单元项目回迁住宅选房活动辅助系统</div>
    <div class="l-content">
        <div class="c-left bounce animated bounceInLeft">
            <img src="@/assets/img/title.png" alt="">
        </div>
        
        <div class="c-right animated bounceInRight">
            <div class="c-top">
                <img src="@/assets/img/logo_1.png" alt="">
            </div>
            <view>
            </view>
            <form>
            <div class="c-ipt">
                <div><el-input placeholder="用户名" style="border:none;" v-model="input1"></el-input></div>
            </div>
            <div class="c-ipt">
                <div><el-input placeholder="密码" style="border:none;" v-model="input2" show-password  @keyup.enter.native="loging "></el-input></div>
            </div>
            </form>
            <el-button :class="['d-btn',input1!=''&&input2!=''?'':'no-btn']" @click="loging" :icon="loading?'el-icon-loading':''">{{btnMsg}}</el-button>
            <div class="tt2">
                <div>版本号：V1.0</div>
                <div>技术支持：深圳知进创新科技有限公司</div>
            </div>
        </div>
    </div>
 </div>
</template>

<script>
export default {
  data () {
    return {
        btnMsg:'登录',
        loading:false,
        input1:'',
        input2:'',
        routes:[],
    }
  },
  methods:{
      enter(){
          console.log(111111)
      },
      loging(){
          if(this.input1 != '' && this.input2 != ''){
              this.loading = true;
              this.btnMsg = "登录中...";
              localStorage.clear();
              let dt = { username:this.input1, password:this.input2}
              this.$http.post('/login/login',dt).then(res=>{
                  console.log(res)
                  if(res.data.code == 0){
                    this.loading = false;
                    this.btnMsg = "登录成功";
                    localStorage.setItem('job',res.data.data.job)
                    sessionStorage.setItem('login','login')
                    localStorage.setItem('taihao',res.data.data.taihao)
                    localStorage.setItem('name',res.data.data.name)
                    localStorage.setItem('uin',res.data.data.uin)
                    for(let i = 0;i<this.routes.length;i++){
                        if(this.routes[i].key == res.data.data.job){
                           return this.$router.push(this.routes[i].path)
                        }
                    }
                    // this.routes.forEach((item,index)=>{
                    //         if(item.key == res.data.data.job){
                    //             this.$router.push(item.path)
                    //         }
                    // })
                  }else{
                    this.loading = false;
                    this.btnMsg = "重新登录";
                    this.$message.error(res.data.msg);
                  }
              })
          }else{    
              this.$message({
                showClose: true,
                message: '请输入账号密码',
                type: 'error'
                });
          }
      }
  },
  created(){
      this.routes = this.$router.options.routes
      console.log(this.routes)
  }
}

</script>
<style lang="less" scoped>
div{
    box-sizing: border-box;
}
.fliter{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/img/back-login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // filter: blur(134px);
    z-index: -1;
    overflow: hidden;
}
.login{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}
.l-content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .c-left{
        width: 380px;
        margin-right: 50px;
        height: 100%;
        position: relative;
        img{
            width:560px;
            height: 80%;
            position: absolute;
            top:0;
            right: 50%;
            margin-right: -215px;
        };
        .c-left_title{
            position: absolute;
            bottom: 0;
            text-align: center;
            font-size: 23px;
            color: #ef9d70
        }
    }
    .c-right{
        position: relative;
        width: 380px;
        padding: 50px 40px 40px;
        background: #fff;
        border-radius: 10px;
        .c-top{
            position: relative;
            margin-bottom: 20px;
            text-align: center;
            line-height: 0;
            img{
                height: 100%;
            }
        }
        .c-ipt{
            padding: 10px 10px 0;
            margin-bottom: 10px;
            font-size: 18px;
            border-bottom: 1px solid #DCDFE6;
            color: #000;
            .c-tip{
                margin-top: 40px;
            }
        }
    }
}
/deep/.el-input__inner{
    border: none;
    padding: 0;
    // background-color: transparent ! important;
}
/deep/input:-internal-autofill-selected{
    background-color: #fff!important;
}
.d-btn{
    background-color: #4f940a;
    text-align: center;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    width: 100%;
    padding: 12px 8px;
    margin-top: 20px;
    cursor: pointer;
}
.l-tip{
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    color: #75b7f0;
}
.no-btn{
    opacity: .4;
    pointer-events: none;
}
.slog{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    color: #fff;
}
.tt2{
    color: #666;
    line-height: 1.6;
    font-size: 14px;
    margin-top: 12px;
}
.tt1{
    position: fixed;
    top: 80px;
    font-size: 26px;
    color: #ef9d70
    // text-align: center;
    // margin: 0 auto 50px;
    // font-size: 26px;
    // color: #fff;
    // letter-spacing: 0px;
}
.l-title{
    text-align: center;
    font-size: 32px;
    color: #ef9d70;
    margin: -60px 0 60px;
    font-weight: bold;
    opacity: 0;
    animation: fDa 2s infinite;
    -webkit-animation:fDa 2s infinite; /* Safari 和 Chrome */
    animation-iteration-count:1;
    -webkit-animation-iteration-count:1; /* Safari 和 Chrome */
    animation-delay:1s;
    -webkit-animation-delay:1s; /* Safari 和 Chrome */
    animation-fill-mode: forwards;
}
@keyframes fDa {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
</style>